.card {
    flex: 0 1 40%;
    box-sizing: border-box;
    padding: 10px;
    /* margin: 10px; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  }
  
  .card-h3 {
    margin-bottom: 5px;
    font-size: 1.5em;
    font-weight: bold !important;
    color: #b48e75;
    text-align: center;
    padding-left: 20px;
  }

  .card-h3-card1 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #b48e75;
    text-align: center;
    padding-left: 20px;
    background:#2c2b2b;

  }
  
  .card-p {
    margin: 0;
    font-size: 0.8em;
    color: #b48e75;
    text-align: left;
    padding-left: 20px;
  }

  .card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .card:hover {
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
  
  
  @media (max-width: 768px) {
    .card {
      flex: 1 1 45%;
    }
  }
  
  @media (max-width: 480px) {
    .card {
      flex: 1 1 100%;
    }
  }
  
  .card .carousel .control-dots .dot {
    width: 1px; /* Adjust the width */
    height: 1px; /* Adjust the height */
    margin: 0 5px; /* Adjust the spacing */
    border-radius: 10%; /* Ensure the dots are circular */
  }

.carousel.carousel-slider .control-arrow {
  font-size: 1em;
  width: 1.5em;
}