.loading-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 3em;
  height: 3em;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #1fb8f9; /* Color for the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
