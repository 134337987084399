/* Collab.css */

.invite-page-container {
    max-width: 37.5em; /* 600px / 16 = 37.5em */
    margin: 3.125em auto; /* 50px / 16 = 3.125em */
    padding: 1.25em; /* 20px / 16 = 1.25em */
    background-color: #f9f9f9;
    border-radius: 0.5em; /* 8px / 16 = 0.5em */
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1); /* 10px / 16 = 0.625em */
    font-family: 'Arial', sans-serif;
}

.invite-page-container h1 {
    font-size: 1.5em; /* 24px / 16 = 1.5em */
    font-weight: bold;
    color: #333;
    margin-bottom: 1.25em; /* 20px / 16 = 1.25em */
    text-align: center;
}

.invite-page-container p {
    font-size: 1em; /* 16px / 16 = 1em */
    color: #555;
    margin-bottom: 1.875em; /* 30px / 16 = 1.875em */
    text-align: center;
}


.invite-page-container button {
    width: 48%;
    padding: 0.625em; /* 10px / 16 = 0.625em */
    font-size: 0.875em; /* 14px / 16 = 0.875em */
    font-weight: bold;
    color: #fff;
    background-color: #b57156;
    border: none;
    border-radius: 0.25em; /* 4px / 16 = 0.25em */
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.invite-page-container button:hover {
    background-color: #9f5b47;
}

.invite-page-container button:focus {
    outline: none;
}

.invite-page-container button:not(:last-child) {
    margin-right: 0.25em; /* 4% of width */
}

.invite-page-container button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

@media (max-width: 30em) { /* 480px / 16 = 30em */
    .invite-page-container {
        padding: 0.9375em; /* 15px / 16 = 0.9375em */
    }

    .invite-page-container h1 {
        font-size: 1.25em; /* 20px / 16 = 1.25em */
    }

    .invite-page-container p {
        font-size: 0.875em; /* 14px / 16 = 0.875em */
    }

}
