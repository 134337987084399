/* body {
    font-family: "Nunito Sans", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    overflow-x: hidden;
    background-color: #1c1c1c;
    color: white;
} */

/* header {
    background: #cccccc;
    color: white;
    padding: 100px 0;
    text-align: center;
    position: relative;
} */

/* #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
} */

main {
  flex: 1;
}

header .container {
  animation: fadeIn 1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
}

.fullbody {
  height: 100%;
}

.header-background {
  position: relative;
  overflow: hidden;
  background-color: #cccccc;
  color: white;
  padding: 100px 0;
  text-align: center;
  position: relative;
}

.header-background img {
  filter: grayscale(100%);
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-container {
  width: 100%;
  margin-left: 10px;
  margin: auto auto !important;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 20px;
  padding: 0;
}

.photo-album-container {
  width: 90%;
  /* margin-left: 10px; */
  margin: auto auto !important;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: stretch;
  flex-wrap: wrap;
  /* margin: 20px; */
  padding: 0;
}

.header-h1 {
  font-size: 48px;
  margin-bottom: 10px;
  color: white;
}

.header-p {
  font-size: 18px;
  margin: 0;
  color: white;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  /*background: #1c1c1c;*/
  /*color: #b48e75;*/
  background-image: linear-gradient(
    90deg,
    #66d3ff,
    #9a6aff 38%,
    #ee75cb 71%,
    #fd4d77
  );
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition:
    background 0.3s,
    color 0.3s,
    transform 0.3s;
  margin-top: 20px;
  font-size: 0.7em;
  font-weight: bold;
}

.cta-button:hover {
  background-image: linear-gradient(
    90deg,
    #66d3ff,
    #9a6aff 38%,
    #ee75cb 71%,
    #fd4d77
  );
  color: white;
  transform: scale(1.03);
}

section {
  padding: 50px 0;
  text-align: center;
}

.steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.step {
  flex: 1 1 30%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: transform 0.3s;
  background: white;
  color: #1c1c1c;
}

.step:hover {
  transform: translateY(-10px);
}

.step:hover .icon {
  transform: rotate(360deg);
}

footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
  padding: 20px 0;
}

.login-container {
  flex: 0 1 30%;
  box-sizing: border-box;
  /*padding: 10px;*/
  /* margin: 10px; */
  /* border-radius: 8px; */
  /*box-shadow: 0 4px 8px rgba(0,0,0,0.1);*/
  text-align: center;
  /* background-color: #1e293b; */
}

/* .arrow-image{
    width: 5%;
    margin: 3px;
    margin-top: 9em;
    padding: 3px !important;
} */

@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step {
    margin-bottom: 30px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 2em;
  width: 80%;
  max-width: 25em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: visible; /* Ensure the modal does not overflow */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

@media (max-width: 768px) {
  .close-modal-button {
    top: 5px;
    right: 5px;
    font-size: 20px;
    padding: 0.1em 0.1em;
    width: 10%;
    color: black;
  }
}

.OurProducts-h2 {
  color: #aaa7a7; /* Example: white color */
  font-size: 2rem; /* Example: 1.5 rem font size */
  text-align: center; /* Centered text */
  /* margin-top: 10px; */
  margin-bottom: 10px; /* Space from the button above */
}

.landing-body {
  background-color: white;
  color: black;
  /* padding: 8.75rem 15.125rem; */
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: grid;
  row-gap: 5rem;
  justify-content: center;
}

.feature {
    display: grid;
    row-gap: 5.25rem;
}

.feature-body {
    display: flex;
    column-gap: 3.125rem;
}

.colorHeading {
  background: linear-gradient(
    90deg,
    #66d3ff,
    #9a6aff 38%,
    #ee75cb 71%,
    #fd4d77
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.featureName {
  font-size: 3.125rem;
  font-weight: 600;

}

.feature:nth-child(even) {
    .featureHeading {
        text-align: right;

    }
} 

@media (max-width: 768px) {
    .landing-body {
        row-gap: 3rem;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        img {
            max-width: 80%;
        }
    }
    .feature {
        row-gap: 1rem;
    }
    .feature-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1.5rem;
    }
    .featureName {
        font-size: 2rem;
    }
    .featureHeading {
        text-align: center !important;
    }
    .feature:nth-child(even) {
        .feature-body {
            flex-direction: column-reverse;
        }

    }
  }
