/* Flashback Font */
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap');
body{
  font-family: "Nunito Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
          "wdth" 100;
}

/* html, body{
  height: 100% !important;
} */

body {
  margin: 0;
  font-family:"Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
  color: #f0f0f0;
  font-family: "Nunito Sans", sans-serif;
  scroll-behavior: smooth;
  height: 100%;
}


::-webkit-scrollbar {
  /* display: none;
  appearance: none;
  -webkit-appearance: none; */
  /* width: 0px; */
/* background: transparent;  */

}

code,p {
  font-family: "Nunito Sans", sans-serif !important;
;
}

#root{
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;
}