.app-bar {
    padding-top: env(safe-area-inset-top);
    height: 4.375rem;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid ;
    border-image: linear-gradient(90deg,#66D3FF 0%, #9A6AFF 38%,  #EE75CB 71%, #FD4D77 100%);
    border-image-slice: 1;
}

.app-bar-logo {
    display: flex;
    gap: 0.3125rem;
    height: 100%;
    align-items: center;
}

.app-bar-logo img {
    height: 3rem; /* Adjust the logo height */
    width: auto; /* Maintain aspect ratio */
    margin-right: 2rem; /* Adjust spacing */
}

.app-bar-socials {
    display: flex;
    gap: 1.75rem;
    color: black;
    > a {
        margin-left: 0px;
        width: 1.75rem;
        height: 1.75rem;
        display: grid;
        justify-content: center;
        align-items: center;
    }
}
.logout-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s;
}

.logout-button:hover {
    color: #EE75CB;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1200;
    display: flex;
    justify-content: center;
    align-items: center; /* Center the modal vertically and horizontally */
  }
  
  /* Modal content styling (centered and responsive) */
  .modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    max-width: 400px;
    width: 90%;
    position: relative;
    animation: slideIn 0.5s ease-out; /* Smooth animation for entrance */
  }
  
  /* Modal title styling */
  .modal-content h6 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0.5rem;
  }
  
  /* Modal description styling */
  .modal-content p {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 1rem;
  }
  
  /* TextField and Autocomplete styling */
  .modal-content .MuiTextField-root,
  .modal-content .MuiAutocomplete-root {
    margin-bottom: 1rem;
  }
  
  .modal-content .MuiInputBase-input,
  .modal-content .MuiAutocomplete-input {
    padding: 10px;
    font-size: 0.875rem;
  }
  
  .modal-content .MuiInputLabel-root {
    font-size: 0.875rem;
    color: #666666;
  }
  
  .modal-content .MuiAutocomplete-option {
    font-size: 0.875rem;
    padding: 8px 16px;
  }
  
  .modal-content .MuiAutocomplete-option:hover {
    background-color: #f5f5f5;
  }
  
  /* Error state for TextField/Autocomplete */
  .modal-content .Mui-error .MuiInputBase-input {
    border-color: #d32f2f;
  }
  
  .modal-content .Mui-error .MuiInputLabel-root {
    color: #d32f2f;
  }
  
  /* Button styling for SUBMIT */
  .modal-content .MuiButton-root {
    background-color: #1fb8f9;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 4px;
    text-transform: none;
    transition: background-color 0.3s ease;
  }
  
  .modal-content .MuiButton-root:hover {
    background-color: #0000ff;
  }
  
  /* Responsive adjustments for mobile (e.g., iPhone 14 Pro Max) */
  @media (max-width: 480px) {
    .app-bar {
      padding: 0.25rem 0.5rem;
    }
  
    .app-bar-logo img {
      height: 2.5rem; /* Smaller logo on mobile */
      margin-right: 1rem; /* Adjust spacing on mobile */
    }
  
    .logout-button {
      padding: 6px 12px;
      font-size: 0.75rem;
    }
  
    .modal-content {
      padding: 1rem;
      width: 85%;
      max-width: 350px; /* Slightly smaller max-width for mobile */
    }
  
    .modal-content h6 {
      font-size: 1rem;
    }
  
    .modal-content p {
      font-size: 0.75rem;
    }
  
    .modal-content .MuiButton-root {
      padding: 8px 16px;
      font-size: 0.75rem;
    }
  
    .modal-content .MuiInputBase-input,
    .modal-content .MuiAutocomplete-input {
      font-size: 0.75rem;
      padding: 8px;
    }
  
    .modal-content .MuiInputLabel-root {
      font-size: 0.75rem;
    }
  
    .modal-content .MuiAutocomplete-option {
      font-size: 0.75rem;
      padding: 6px 12px;
    }
  }
  
  /* Animation for modal entrance */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }