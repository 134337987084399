.how-it-works {
    color: #000000;
    width: 26.875rem;
    .heading {
        font-weight: 600;
        font-size: 34px;
        margin-bottom: 28px;
        display: block;
    }
    .line {
        margin: 0 0 28px 0;
        background-color: #000000;
        border-width: 0;
        height: 1px;
    }
}



@media (max-width: 768px) {
    
    .how-it-works {
        width: 80%;
    }
}

.QA-group {
    /* row-gap: 28px; */
    display: flex;
    flex-direction: column;
}