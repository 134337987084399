@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");


/* Toast Start */

.Toastify__toast {
  font-family: "Nunito Sans", sans-serif !important;
}


.Toastify__close-button {
width: max-content;
height: auto;
align-self: center !important;
}

.Toastify__toast-icon img {
width: 100%;
height: auto;
}

.Toastify__progress-bar-theme--light {
background: linear-gradient(to right, 90deg,#66d3ff,#9a6aff 38%,#ee75cb 71%,#fd4d77) !important;
}

/* Toast End */

.App {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
}

.StyledFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
}

.footer-container {
  padding: 8px 16px;
  max-width: 1920px;
  margin: 0 auto;
}


body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  padding-bottom: 120px; /* Same as footer height */
  box-sizing: border-box;
  /*background-image:url("./Media/logo.svg");*/
  /*background-repeat: repeat-x;*/
  /*background-size: 10 em;*/
}

button {
  cursor: pointer;
}

.post {
  width: 400px;
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border: 1px solid lightgray;
  font-family: "Nunito Sans", sans-serif;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.post:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.post .title {
  flex: 20%;
  border-bottom: 1px solid lightgray;
  background-color: dodgerblue;
  display: grid;
  place-content: center;
  color: white;
}

.post .body {
  flex: 60%;
  display: grid;
  place-content: center;
}

.post .footer {
  flex: 20%;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: dodgerblue;
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer .username {
  flex: 50%;
}
.footer .buttons {
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.likeBttn {
  color: rgba(250, 250, 250, 0.8);
}
.unlikeBttn {
  color: white;
}

.buttons label {
  margin-right: 20px;
  margin-left: 10px;
}

/* CREATE POST PAGE CSS */

.createPostPage {
  font-family: "Nunito Sans", sans-serif;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: auto;
  padding: 20px;
  border: 5px solid dodgerblue;
  border-radius: 5px;
}

.formContainer #inputCreatePost {
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid grey;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 20px;
}

.formContainer button {
  margin-top: 15px;
  height: 40px;
  border: none;
  background-color: lightskyblue;
  border-radius: 5px;
  color: white;
}

.formContainer button:hover {
  cursor: pointer;
  background-color: dodgerblue;
}

/* NAVBAR */

.navbar {
  width: 100%;
  height: 5em;
  background-color: dodgerblue;
  display: flex;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  position: fixed;
}
.navbar .links {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .loggedInContainer {
  flex: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
a {
  /* margin-left: 20px; */
  text-decoration: none;
  color: blue;
}

.navbar button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  background-color: white;
}

.navbar h1 {
  text-align: right;
  margin-right: 10px;
  color: white;
  font-weight: lighter;
}

/* INDIVIDUAL POST */
.postPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.leftSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
}

.rightSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
  display: flex;
  flex-direction: column;
}

.rightSide .addCommentContainer {
  flex: 20%;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 25px;
}

.addCommentContainer input,
.button,
.submit {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
}

.addCommentContainer button {
  background-color: dodgerblue;
  color: white;
}

.addCommentContainer input {
  border: 2px solid dodgerblue;
}

.rightSide .listOfComments {
  flex: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listOfComments .comment {
  width: 70%;
  height: auto;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 20px;
}

#individual {
  height: 500px;
  width: 600px;
}

#individual .title,
#individual .footer {
  font-size: 30px;
}

#individual .body {
  font-size: 25px;
}

/* LOGIN */

.loginContainer {
  display: inline-block;
  /* float: left; */
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.loginContainer input,
.button,
.submit {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
  margin: 10px;
}

.loginContainer .button,
.loggedInContainer.submit {
  background-color: dodgerblue;
  color: white;
}

.loginContainer input {
  border: 2px solid dodgerblue;
}

/* dark mode */

.dark-mode {
  background-color: #1c1c1c;
  color: #f0f0f0;
  font-family: "Nunito Sans", sans-serif;
}

/* toggle switch */
.toggle-switch {
  position: fixed;
  display: inline-block;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Darktitle {
  position: fixed;
  top: 20px;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  right: 70px;
  width: 60px;
  line-height: 1.2;
}
/*styling for Images to be displayed*/
.imageGalleryContainer {
  width: 30%;
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  overflow-y: scroll;
  float: right;
}

.imageGalleryContainer img {
  max-height: 300px; /* Adjust maximum width as needed */
  border-radius: 8px; /* Optional, for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional, for a slight shadow */
}
@media (max-width: 600px) {
  .imageGalleryContainer img {
    max-width: 100%; /* Larger images on smaller screens */
  }
}

@media (max-width: 768px) {
  .App {
    padding-bottom: 100px; /* Smaller padding for mobile */
  }

  body {
    padding-bottom: 100px;
  }

  .footer-container {
    padding: 6px 12px;
  }
}

/* Ensure content doesn't go under footer */
.content-wrapper {
  position: relative;
  z-index: 1;
  background: #fff;
  min-height: calc(100vh - 120px);
}

/* Toast container adjustment */
.Toastify__toast-container {
  margin-bottom: 120px; /* Prevent toast from being hidden by footer */
}

/*New Registration & Login CSS */
.login-form input {
  display: block;
  width: auto;
  padding: 1em;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: 0;
}

.login-form .error {
  /* outline: 1px solid red; */
  border: 1px solid #f66060;
}

.login-form input[type="checkbox"] {
  display: block;
  width: auto;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: 0;
}
.login-form a {
  display: block;
  width: auto;
  padding: 1em;
}

.login-form .button,
.login-form .submit {
  display: block;
  width: 100%;
  padding: 1em;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}
.login-form .button {
  background-color: #42b72a;
}
.login-form label {
  display: flex;
}

.submitPhoto {
  background-color: #64a856 !important;
}

.takePhoto {
  background-color: #ffffff !important;
}

.termsAndConditionButton {
  color: #bbb6b6 !important;
  text-decoration: underline !important;
  text-underline-offset: 0.21em !important;
}

.loginRight {
  /* float: Right; */
  display: inline-block;
}
.loginLeft {
  display: block;
  max-width: 25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  /*padding: 25%;*/
}

/*Facebook Styled Registration & Login CSS */
.loginBody {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-form-container {
  /*margin-left: 100px;*/
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: max-content;
  display: contents;
  align-items: center;
}

.btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: inline-grid;
  /*flex-direction: column;*/
  /*padding: 10%;*/
  max-width: 20em;
  width: -webkit-fill-available;
}

.login-form input {
  /* margin-bottom: 1em; */
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  width: -webkit-fill-available;
}

.login-form .button,
.login-form .submit {
  padding: 1em;
  margin-bottom: 1em;
  border: none;
  border-radius: 0.5em;
  font-size: 1em;
  cursor: pointer;
}

.login-form .submit {
  /* background-color: #1877f2; */
  background-color: #ffffff;
  color: #000000;
}

.login-form .button {
  background-color: #42b72a;
  color: white;
}

.login-form a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1em;
  font-weight: 1000;
}

p {
  text-align: center;
  font-size: 1em;
  color: grey;
  margin-top: 2em;
}

.loginForgotPassword {
  padding-left: 25%;
}

.loginLogoBox {
  padding: 0.8em 2rem;
  margin-top: 0.1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  /* background-color: red; */
}

.loginLogoBox h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1em;
  color: #bbb6b6;
  font-size: 2.5em;
  position: relative;
  width: fit-content;
}

.loginLogoBox h2::after {
  /* content: "Auto Curate & Instant Share Memories"; */
  position: absolute;
  bottom: -2em;
  display: block;
  text-align: center;
  font-size: 0.3em;
  letter-spacing: 0.1em;
}

.loginLogoBox img {
  width: 1.1em;
  height: 1em;
}


.loginLogo {
  font-family: "Nunito Sans", sans-serif; /* This is a placeholder; you'd need the actual font used by Facebook */
  color: #1877f2; /* This is the Facebook blue */
  font-size: 3em; /* Adjust the size as needed */
  font-weight: 700; /* Facebook's logo is bold */
  letter-spacing: -0.05em; /* Adjust letter spacing to closely match the logo */
  height: fit-content;
  width: fit-content;
  max-width: 25em;
}
.logoCaption {
  font-size: 0.5em;
  /* color: #1c1e21; */
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 0.05em;
}

.Congrats {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 2em; /* Adjust the size as needed */
  font-weight: 700; /* Facebook's logo is bold */
  letter-spacing: -0.05em; /* Adjust letter spacing to closely match the logo */
  height: fit-content;
  width: fit-content;
  max-width: 25em;
}

.CongratsMessage {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 1em; /* Adjust the size as needed */
  font-weight: 700; /* Facebook's logo is bold */
  letter-spacing: -0.05em; /* Adjust letter spacing to closely match the logo */
  height: fit-content;
  width: fit-content;
  max-width: 25em;
}

.webcam {
  width: 250;
  height: 400;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 10em;
  height: 10em;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status-post-container {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
  margin-top: 15%;
}

#statusInput {
  width: 80%;
  height: 2em;
  border: 1px solid #ccd0d5;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
}

#fileInput {
  display: block;
  margin-bottom: 10px;
}

#postButton {
  background-color: #4267b2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

#postButton:hover {
  background-color: #365899;
}

.navbar img {
  max-height: 2 em;
}

/*Sample Code*/
.collage {
  /* background-image: url("/images/background.png"); */
  position: relative;
  width: 500px; /* Set to the desired width of your collage */
  height: 300px; /* Set to the desired height of your collage */
  touch-action: none;
  overflow: hidden;
}

.collage .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%; /* Half the width of the collage container */
  height: 50%; /* Half the height of the collage container */
  object-fit: cover; /* This ensures the images cover their area without distorting */
  z-index: 1; /* Background images are behind the foreground */
  touch-action: none;
  overflow: hidden;
}

.collage .background:nth-child(2) {
  left: 50%; /* Moves the second image to the right half */
}

.collage .background:nth-child(3) {
  top: 50%; /* Moves the third image to the bottom left */
}

.collage .background:nth-child(4) {
  top: 50%;
  left: 50%; /* Moves the fourth image to the bottom right */
}

.collage .foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Foreground image covers the entire collage */
  height: 100%;
  z-index: 2; /* Ensures the foreground is on top */
  pointer-events: none;
}
.background .image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*pop up css*/
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure it's above other content */
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.flashback-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust based on your design */
}

.flashback-card {
  cursor: pointer;
  background-color: #f0f0f0;
  color: black;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.flashback-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* DownloadButton.module.css */
.downloadButton {
  background-color: transparent;
  border: none;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -36px;
  left: 10px;
  border: 2px solid #fbc90026;
  left: calc(25%);
  bottom: calc(25%);
}

.downloadIcon {
  margin-left: 20px;
}

.image-gallery {
  max-width: calc(46vh);
}

.registration-message {
  text-align: center;
  padding-top: 50px;
  font-size: 24px;
  color: green;
}

/* Base styles */
.wrapper {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(6.9em, 1fr)
  ); /* Three images per row, each with a minimum width of 300px */
  gap: 0.65em; /*Gap between grid items */
  background-size: 8em;
  width: calc(100% - 1.4em);
  margin-left: 0.5em;
}

.wrapper-images {
  position: relative;
  width: 8em;
  height: auto;
  object-fit: cover;
}

.wrapper-images img {
  width: 7.5em;
  padding: 0.5em;
  height: 8em;
  object-fit: cover;
}

.wrapper-images p {
  margin: -0.5em 0 0; /* Add some margin above the text for spacing */
  font-size: 1em; /* Adjust font size as needed */
  /*font-weight: bold;*/
  color: #949494; /* Change text color if needed */
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust columns for smaller screens */
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  /* background-color: rgba(249, 243, 243, 0.5); */
  /* z-index: 10; */
}
.overlay img {
  max-width: 90%;
  max-height: 80%;
  padding: 1px;
  /* border: 1px solid white; */
  position: centre;
  padding-top: 10px;
}
.overlay > .dismiss {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 4em;
  height: 4em;
  background-color: #ff6347; /* Tomato color */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;

  /*position: absolute;*/
  /*top: 1em;*/
  /*right: 1em;*/
  /*font-size: 1.5em;*/
  /*color: #ffffff;*/
  /*cursor: pointer;*/
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
  pointer-events: auto;
}

.overlay.active {
  pointer-events: none; /* Disable pointer events on elements beneath the overlay */
}
/* .overlay > button {
  position: absolute;
  top: 1em;
  left: 1em;
  font-size: 1.5em;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
} */
/* .overlay-arrows_left {
  display: flex;
  /* background-color: #6d6d6d; 
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 999;
} 

 .overlay-arrows_left svg {
  width: 30px;
  height: 30px;
}

.overlay-arrows_right svg {
  width: 30px;
  height:30px;
}

.overlay-arrows_right {
  display: flex;
  /* background-color: #6d6d6d; 
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 999;
} */
.terms-container {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section {
  margin-bottom: 20px;
  text-align: left;
}

h2,
h3 {
  color: #333;
  margin-bottom: 0;
}

ul {
  list-style-type: disc; /* Use bullet points */
}

li {
  color: #666;
  margin-left: 20px; /* Adjust the indentation of bullet points */
}

/* Image Modal Additional CSS */

.dNone {
  display: none;
}

.hidden {
  visibility: hidden;
  opacity: 1;
  transition: visibility 0s ease-in;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.modalOuter {
  display: flex;
  width: max-content;
  height: -webkit-fill-available;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalOuter img {
  /* width: 100%; */
  /* height: 100%; */
  /* border: 2px solid #b0b3b6 !important; */
  padding: 0;
  max-width: 90%;
  max-height: 80%;
}

.modalOuter > .dismiss {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 3rem;
  height: 3rem;
  background-color: #b48d75;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;

  /*width: 88%;*/
  /*display: flex;*/
  /*justify-content: flex-end;*/
  /*margin-bottom: 1em;*/
  /*font-size: 1.5em;*/
  /*color: #ffffff;*/
  /*z-index: 999;*/
  /*cursor: pointer;*/
}

.dismiss span {
  color: inherit;
}



/* ToolBox */

.imageToolBox {
  width: 90%;
  display: flex;
  background-color: #000000;
  padding: 1em;
  gap: 1em;
  font-size: 0.8em;
  color: #ffffff;
  align-items: center;
}

.imageToolBox svg {
  width: 1em;
  height: 1em;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.imageToolBox > div {
  padding-left: 1.5rem;
  letter-spacing: 1px;
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
}

.dFlex {
  display: flex;
  gap: 0.5em;
}

.dFlexCol {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

#download {
  cursor: pointer;
}

.bgRed {
  animation: like 1s cubic-bezier(0.17, 0.89, 0.32, 1.49) forwards;
  /* visibility: hidden !important; */
}

@keyframes like {
  0% {
    visibility: hidden !important;
    fill: red;
    color: red;
  }
  75% {
    visibility: visible !important;
    opacity: 1;
    fill: red;
    color: red;
    transform: scale(1.4);
  }
  100% {
    visibility: visible !important;
    opacity: 1;
    fill: red;
    color: red;
    transform: scale(1.1);
  }
}

.isDownloading {
  animation-play-state: running;
  position: relative;
  margin-left: 15%;
}

.isDownloading:before,
.isDownloading:after {
  content: " ";
  display: block;
  position: absolute;
  left: -16%;
  color: black;
  font-weight: 600;
  top: 60%;
}

/* Download box shape  */
.isDownloading:before {
  width: 10px;
  height: 2px;
  border-style: solid;
  border-width: 0 2px 2px;
}

/* Download arrow shape */
.isDownloading::after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;

  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;

  animation: downloadArrow 2s linear infinite;
  /* animation-play-state: paused; */
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
  /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
  0% {
    margin-top: -7px;
    opacity: 1;
  }

  0.001% {
    margin-top: -15px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 0;
  }
}

.flagOption {
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: 0.9em;
  gap: 0.5em;
  padding: 0.5em;
  justify-content: flex-start;
  width: 100%;
  color: black;
  /* min-width: 8em; */
 
}

.fb__value-container{
  padding: 0 0.3em !important;
  outline: 0;
}

.customControl{
  gap:0;
  width: max-content;
  outline: 0;
}


.fb__indicator{
  padding: 8px 3px !important;
}

.flagOption::selection{
  color: white;
}

.phoneOuter{
  display: flex;
  position: relative;
}

.phoneOuter input {

  /* margin: 10px 0;
  border: 1px solid #ddd;
    border-radius: 4px;
  /* overflow: hidden; */
  /* display: flex;  */

  /* .login-form input { */
  display: block;
  width: auto;
  padding: 1em;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: 0;

  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  width: -webkit-fill-available;
  color: inherit;
}

.phoneOuter input {
  margin: 0;
  /* padding-left: 6em; */
  overflow: hidden;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  /* color: inherit; */
  color: black;
}

.countryCode {
  /* display: flex; */
  width: max-content;
  align-items: center;
  gap: 0.4em;
  /* position: absolute; */
  /* background-color: #f0f0f0; */
  /* top: 10px; */
  /* height: 100%; */
  /* padding: 0.5em; */
  /* color: #000000; */
  font-size: 14px;
  outline: 0;
  border: 0;
  color: inherit;
}

.fb__control{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  outline: 0 !important;
  color: inherit;
  border: 0 !important;
  box-shadow: none !important;
}

.fb__control:focus{
  outline: 0 !important;
}

.countryCode input{
  outline: 0;
  border: 0;
}

.countryCode img,
.iconImg {
  width: 2em;
  height: 2em;
  /* flex: 0.3; */
  /* align-self: flex-start; */
}


.image_favourite_down{
  position: absolute;
  bottom: 0.5em;
  right: 0.6em;
  fill: red;
  color: transparent;
  width: 1em;
  height: 1em;
}

.cursor-pointer{
  cursor: pointer;
}

.Toastify__toast-container{
  margin-top: 45em;
  padding-left: 1em;
  padding-right: 1em;
}