.input-field {
  width: 100%;
  height: 3.375rem;
  border-radius: 4px;
  border: 2px solid #dddddd;
  background-color: white;
  padding-left: 1.25rem;
  padding-right: 1rem;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  box-sizing: border-box;
  margin-top: 0.5rem;
  :disabled {
    background-color: white;
  }
}

input[type="file"] {
  padding: 1rem;
  cursor: pointer;
  text-align: justify;
}
