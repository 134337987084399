.ip-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 images per row for larger screens */
  gap: 0.1em; /* Adjust the gap between images as needed */
  margin: 2em;
}

/* Responsive adjustments for tablets */
@media (max-width: 1024px) {
  .ip-wrapper {
    grid-template-columns: repeat(4, 1fr); /* 4 images per row for tablets */
    margin: 1em;
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .ip-wrapper {
    grid-template-columns: repeat(3, 1fr); /* 2 images per row for mobile devices */
    margin: 1em;
  }
}

.ip-wrapper-images {
  position: relative; /* Ensures the heart icon can be positioned within the image */
  margin: 0.2em;
}

.ip-wrapper-images img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10%;
  padding: 0.2em;
  transition: transform 0.2s; /* Smooth scaling on hover */
}

.ip-wrapper-images:hover img {
  transform: scale(1.03); /* Scale image on hover */
}

.heart-icon {
  position: absolute;
  bottom: 0.5em;
  right: 0.3em;
  border-radius: 50%;
  padding: 5px;
  width: 2em;
  height: 2em;
  /* background-color: white; Default background color */
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth background color transition */
}

/* Ensure heart icon stays visible on smaller screens */
@media (max-width: 768px) {
  .heart-icon {
    width: 1.5em;
    height: 1.5em;
    bottom: 0.3em;
    right: 0.2em;
  }
}