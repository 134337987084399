/* Footer.css */
.footer {
  background: #ffffff;
  color: #000000;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  border-top: 2px solid #686868;
  /* position: absolute; */
  /* bottom: 0; */
}

.contact-container {
  display: flex;
  align-items: center;
  width: 9em;
}

.social-container {
  display: flex;
  align-items: center;
  max-width: 6em;
  gap: 20px;
}

.footer p {
  margin: 2px 0;
}

.footer a {
  color: white;
  text-decoration: none;
  margin-left: 0;
}

.social-media-links {
  margin-top: 5px;
}

.social-media-links a {
  margin: 0 5px;
}

.social-container img {
  width: 1rem;
  height: 1rem;
}

/* New CSS to ensure the footer is always at the bottom */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.contactus p{
  display: contents;
}

.contactus{
  width: 8em;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  white-space: nowrap;
  > span:first-of-type {
    opacity: 0.8;
  }
}

.copy-right-info {
  display: flex;
  flex-direction: column;
  align-items: end;
  opacity: 0.8;
  white-space: nowrap;
}
